import type { NavigationLink } from "~/types";

export function useNavigationLinks() {
  const { data: handbookSections } = useHandbookSectionsGet({
    enabled: true
  });

  const { locale } = useI18n();

  return computed((): NavigationLink[] => [
    { title: "mainnav.home", icon: ICONS.home, to: "/" },
    {
      title: "mainnav.activity",
      icon: ICONS.activity,
      to: "/activity"
    },
    {
      title: "mainnav.submissions.title",
      icon: ICONS.submissions,
      to: "/submissions",
      minPowerLevel: "junior",
      sublinks: [
        { title: "mainnav.submissions.inbox", icon: ICONS.submitted, to: "/submissions/inbox" },
        { title: "mainnav.submissions.observed", icon: ICONS.observed, to: "/submissions/observation" },
        { title: "mainnav.submissions.rejected", icon: ICONS.rejected, to: "/submissions/rejected" },
        { title: "mainnav.submissions.spam", icon: ICONS.spam, to: "/submissions/spam" },
        { title: "mainnav.submissions.deleted", icon: ICONS.delete, to: "#", disabled: true }
      ]
    },
    {
      title: "mainnav.claims.title",
      icon: ICONS.claims,
      to: "/claims",
      minPowerLevel: "junior",
      sublinks: [
        { title: "mainnav.claims.recent", icon: ICONS.accepted, to: "/claims/recent" },
        { title: "mainnav.claims.completed", icon: ICONS.checked, to: "/claims/completed" },
        { title: "mainnav.claims.discarded", icon: ICONS.discarded, to: "/claims/discarded" }
      ]
    },
    {
      title: "mainnav.forum.title",
      icon: ICONS.forum,
      to: "/forum",
      minPowerLevel: "junior",
      sublinks: [
        { title: "mainnav.forum.channels", icon: ICONS.channels, to: "/forum" },
        { title: "mainnav.forum.dms", icon: ICONS.dms, to: "/forum", disabled: true }
      ]
    },
    {
      title: "handbook.title",
      icon: ICONS.handbook,
      to: "/handbook",
      sublinks: handbookSections.value?.map(({ id, titleDe, titleEn }) => ({
        title: locale.value === "de" ? titleDe : titleEn,
        titleDynamic: true,
        to: `/handbook/${id}`
      }))
      // hideSublinksOnMobile: true
    }
  ]);
}
