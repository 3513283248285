<script setup lang="ts">
const store = useFilteringStore();

const hasFilters = computed(() => {
  return store.scope !== "all" || store.userClaimState.length > 0 || store.categories.length > 0;
});

const activityStore = useActivityStore();
const hasAccess = useHasAccess();
</script>

<template>
  <div class="p-2.5 mx-2.5 flex flex-col gap-2 bg-color-2 border rounded-lg">
    <div class="flex justify-between items-center">
      <h4 class="small uppercase">{{ $t("mainnav.filters.title") }}</h4>
      <BasicButton
        size="xs"
        icon="lucide:x"
        :class="
          cn('bg-color-1', {
            'pointer-events-none opacity-0': !hasFilters
          })
        "
        :tooltip="$t('mainnav.filters.reset')"
        @click="store.reset"
      />
    </div>
    <Separator class="bg-color-3 h-px w-full" />
    <div v-if="hasAccess.editor" class="flex gap-2 items-center">
      <p class="text-sm">{{ $t("mainnav.filters.scope.title") }}:</p>
      <div class="grow">
        <BasicSelect
          size="sm"
          class="w-full"
          :options="ACTIVITY_FILTERS.map((filter: string) => ({ label: filter, value: filter }))"
          :defaultValue="activityStore.filter"
          @update:modelValue="activityStore.setFilter($event)"
        />
      </div>
    </div>
  </div>
</template>
