<script lang="ts" setup>
import { useRoute as useNativeRoute } from "vue-router";

// use vue-router instead of nuxt-router, because nuxt-router is not working correctly after a redirect
// https://github.com/nuxt/nuxt/issues/21340
const nativeRoute = useNativeRoute();

const scrollWrapper = ref();

function scrollToDocument() {
  const doc = document.getElementById("document");
  if (!doc) return;
  const rect = doc.getBoundingClientRect();
  scrollWrapper.value?.scrollTo({ left: rect.left, behavior: "smooth" });
}
watch(() => nativeRoute.params.name, scrollToDocument);
onMounted(() => {
  scrollToDocument();
});
</script>

<template>
  <div
    ref="scrollWrapper"
    :class="
      cn(
        'h-[100dvh] w-full relative flex',
        'overflow-x-auto overflow-y-hidden xl:overflow-hidden',
        'max-xl:snap-x max-xl:snap-mandatory',
        'max-xl:py-12'
      )
    "
  >
    <slot />
  </div>
</template>
